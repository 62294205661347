<template>
  <div>
    <div id="pdf-content">
      <div style="page-break-after: always; padding-right: 1mm" id="content">
        <table style="width: 100%; position: relative; margin-bottom: 2mm">
          <tr>
            <td>HÓA ĐƠN BÁN HÀNG</td>
            <td rowspan="3" class="text-right">
              <img
                :src="order.qr_order"
                alt=""
                style="width: 15mm; height: 15mm"
              />
            </td>
          </tr>
          <tr>
            <td>
              <b>{{ order.tracking_id }}</b>
            </td>
          </tr>
          <tr>
            <td>
              {{ order.pos_code }} -
              {{ formatDateTime(order.created_at, "HH:mm DD/MM/YYYY") }}
            </td>
          </tr>
        </table>

        <table style="width: 100%; position: relative; margin-bottom: 3mm">
          <tr>
            <td>
              K. Hàng: <b>{{ order.buyer_name }} - {{ order.buyer_phone }}</b>
            </td>
          </tr>
          <tr>
            <td>
              N. Viên: <b>{{ order.employee_name }}</b>
            </td>
          </tr>
        </table>

        <div
          class="text-center"
          v-if="order.newItems && order.newItems.length > 0"
        >
          <b>MUA HÀNG</b>
        </div>
        <table
          class="table-border"
          style="width: 100%; position: relative; margin-bottom: 5mm"
          v-if="order.newItems && order.newItems.length > 0"
        >
          <tr class="text-center">
            <td>Sản phẩm</td>
            <td style="width: 10%">SL</td>
            <td style="width: 30%">Thành tiền</td>
          </tr>
          <tr v-for="(newItem, i) in order.newItems" :key="`n_${i}`">
            <td>{{ newItem.name }}</td>
            <td class="text-center">{{ newItem.quantity }}</td>
            <td class="text-right">
              {{ formatNumber(newItem.sum_base_price) }}
            </td>
          </tr>
        </table>

        <div
          class="text-center"
          v-if="order.exchangeItems && order.exchangeItems.length > 0"
        >
          <b>ĐỔI TRẢ</b>
        </div>
        <table
          class="table-border"
          style="width: 100%; position: relative; margin-bottom: 5mm"
          v-if="order.exchangeItems && order.exchangeItems.length > 0"
        >
          <tr class="text-center">
            <td>Sản phẩm</td>
            <td style="width: 10%">SL</td>
            <td style="width: 30%">Thành tiền</td>
          </tr>
          <tr v-for="(exchangeItem, i) in order.exchangeItems" :key="`ec_${i}`">
            <td>{{ exchangeItem.name }}</td>
            <td class="text-center">{{ exchangeItem.quantity }}</td>
            <td class="text-right">
              {{ formatNumber(-exchangeItem.sum_payment_price) }}
            </td>
          </tr>
        </table>

        <div class="text-center"><b>KHUYẾN MẠI</b></div>
        <table
          class="table-border text-right"
          style="width: 100%; position: relative; margin-bottom: 5mm"
        >
          <tr>
            <td style="width: 70%">Khuyến mại Sản phẩm:</td>
            <td>{{ formatNumber(order.product_promotion) }}</td>
          </tr>
          <tr>
            <td style="width: 70%">Giảm trừ giá trị hóa đơn:</td>
            <td>{{ formatNumber(order.exchange_payment_price) }}</td>
          </tr>
          <tr>
            <td>Khuyến mại hóa đơn:</td>
            <td>{{ formatNumber(order.bill_promotion) }}</td>
          </tr>
          <tr>
            <td>Mã giảm giá:</td>
            <td>{{ formatNumber(order.voucher_promotion) }}</td>
          </tr>
        </table>

        <div class="text-center">
          <b>THANH TOÁN: {{ formatNumber(order.payment_price) }}</b>
        </div>
        <table
          class="table-border text-right"
          style="width: 100%; position: relative; margin-bottom: 5mm"
        >
          <tr>
            <td style="width: 70%">Chuyển Khoản:</td>
            <td>{{ formatNumber(order.payment_transfer) }}</td>
          </tr>
          <tr>
            <td>Tiền mặt:</td>
            <td>{{ formatNumber(order.payment_cash) }}</td>
          </tr>
          <tr>
            <td>Trả thẻ:</td>
            <td>{{ formatNumber(order.payment_card) }}</td>
          </tr>
          <tr>
            <td>Dùng điểm:</td>
            <td>{{ formatNumber(order.payment_point) }}</td>
          </tr>
          <tr>
            <td>Trả lại:</td>
            <td>{{ formatNumber(order.payment_return) }}</td>
          </tr>
        </table>

        <div style="margin-top: 16px; margin-bottom: 6px; text-align: center">
          <b>♥ Chúc Quý khách một ngày tốt lành ♥</b>
        </div>
        <div class="text-center" style="margin-bottom: 12px">♥♥♥</div>

        <div v-if="order.pos_can_exchange || order.pos_can_return">
          Sản phẩm được
          <!-- <span v-if="order.pos_can_exchange && order.pos_can_return">/</span> -->
          <span v-if="order.pos_can_return">đổi trả</span>
          <span v-else-if="order.pos_can_exchange">đổi</span>
          hàng trong {{ order.pos_exchange_return_date }} ngày trên hệ thống cửa
          hàng của {{ order.company_name }}.
        </div>
        <div>Sản phẩm cần được giữ:</div>
        <div>a. Nguyên vẹn.</div>
        <div>b. Không bị Ám mùi.</div>
        <div>c. Không bị Ẩm ướt.</div>
        <div>d. Đủ Tem, Tag sản phẩm.</div>
      </div>
    </div>
  </div>
</template>

<script>
// import html2pdf from "html2pdf.js";

export default {
  name: "OrderStamp",
  props: {
    order: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.exportToPDF();
  },
  methods: {
    getDPI() {
      const inchElement = document.createElement("div");
      inchElement.style.width = "1in";
      document.body.appendChild(inchElement);
      const dpi = inchElement.offsetWidth;
      document.body.removeChild(inchElement);
      return dpi;
    },
    pxToMm(pxValue) {
      const dpi = this.getDPI();
      const mmInch = 25.4;
      const inchValue = pxValue / dpi;
      const mmValue = inchValue * mmInch;
      return mmValue;
    },
    exportToPDF() {
      /* const element = document.getElementById('pdf-content')
            const height = this.pxToMm(element.offsetHeight) - 10
            console.log(height)
            const options = {
                filename: `100x80_${this.order.tracking_id}.pdf`,
                html2canvas: { scale: 5 },
                image: {type: 'jpeg', quality: 1},
                jsPDF: { unit: 'mm', format: [80, height] }
            }
            html2pdf().from(element).set(options).save() */
      var iframe =
        document.querySelector("#invoice-print") ||
        document.createElement("iframe");
      iframe.setAttribute("id", "invoice-print");
      document.body.appendChild(iframe);
      iframe.contentWindow.document.body.innerHTML =
        document.querySelector("#pdf-content").innerHTML;

      var printScript = document.createElement("script");
      printScript.innerHTML = `function setPrintStyles(pagesize) {
                                    var documentHeight = ${
                                      iframe.contentWindow.document.body
                                        .offsetHeight /
                                        2 +
                                      50
                                    };
                                    var css = \`@media print { @page { size: $\{pagesize} $\{documentHeight}px; } }\`,
                                        head = document.head || document.getElementsByTagName('head')[0],
                                        style = document.createElement('style');
                                    head.appendChild(style);
                                    style.type = 'text/css';
                                    style.appendChild(document.createTextNode(css));
                                }`;
      iframe.contentWindow.document.head.appendChild(printScript);

      var bodyScript = document.createElement("script");
      bodyScript.innerHTML = `fetch('${window.location.origin}/receipt.css')
                            .then(response => response.text())
                            .then(data => {
                                var head = document.head || document.getElementsByTagName('head')[0],
                                    style = document.createElement('style');
                                head.appendChild(style);
                                style.type = 'text/css';
                                style.appendChild(document.createTextNode(data));
                                setPrintStyles('80mm');
                                window.print();
                            });`;
      iframe.contentWindow.document.body.appendChild(bodyScript);

      this.$emit("onPrinted");
    },
  },
};
</script>

<style lang="scss">
#pdf-content {
  width: 80mm;
  height: auto;
  margin: 0;
  padding: 0;
  font-size: 3mm;

  .table-border {
    border-collapse: collapse;
  }

  .table-border,
  .table-border td,
  .table-border th {
    border: 1px solid #333;
    padding: 1mm;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }
}
</style>
